// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Button from '@components/common/Button'
import { keyframes } from '@utils/constants'
import gsap from '@utils/gsap'
import { useEffect, useRef } from 'react'
import StaticImproveProduct from './StaticImproveProduct'

const hasHover = !globalThis.window?.matchMedia('(hover: none)').matches
export default function ImproveProduct() {
  const productRef = useRef(null)
  if (hasHover) {
    const animateProduct = (scaleKeyframes: number[], startTrigger: string, endTrigger: string, scrub: number | boolean, markersActive?: boolean) => {
      gsap.config({ force3D: false })
      gsap.to(productRef.current, {
        duration: 5,
        keyframes: {
          scale: scaleKeyframes,
          opacity: [1, 1, 0.8, 0.2, 0], // Adjust these values as needed
          ease: 'power1.inOut',
          duration: 5,
        },
        scrollTrigger: {
          trigger: productRef.current,
          scrub,
          start: startTrigger,
          end: endTrigger,
          pin: true,
          markers: markersActive,
        },
      })
      return () => {
        gsap.killTweensOf(productRef.current)
      }
    }
    useEffect(() => {
      return animateProduct(keyframes, 'center center', '+=1400', true)
    }, [])

    return (
      <div ref={productRef} className='-mb-10vh h-950px mt-100px flex flex-col justify-center items-center <lg:-mb-400px <sm:!mb-0px <sm:will-change-opacity <sm:mt-0 <sm:h-570px'
        style={{
          willChange: 'opacity',
          backfaceVisibility: 'hidden'
        }}
      >
        <div className={`relative overflow-hidden h-38rem pt-10rem px-1rem -mt-10 <md:h-31rem <sm:h-28rem ${hasHover && '<sm:-mt-90px'}`}>
          <div style={{ boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.12)' }} className="w-65rem h-65rem rounded-full mx-auto <lg:w-50rem <lg:h-50rem <md:h-44rem <md:w-44rem <sm:w-36rem <sm:h-36rem">
            <img src="/layers/semicircular-product-layer.svg" alt="layer" className="absolute left-50% top-9rem -translate-x-50% -z-1 w-full" />
            <img src="/layers/light-product-layer.svg" alt="layer" className="absolute left-50% -translate-x-50% top-8rem <sm:w-50% <sm:-bottom-60px -z-1" />
            <div style={{ boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.12)' }} className="absolute left-50% -translate-x-50% w-50rem h-50rem rounded-full top-17.5rem <lg:w-40rem <lg:h-40rem <lg:top-16rem <md:h-35rem <md:w-35rem <md:top-15rem <sm:w-29rem <sm:h-29rem"></div>
            <div style={{ boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.12)' }} className="absolute left-50% -translate-x-50%  w-35rem h-35rem rounded-full z-1 top-25.5rem bg-white <lg:w-28.5rem <lg:h-28.5rem <lg:top-22rem <md:top-19rem <md:h-27rem <md:w-27rem <sm:w-22.5rem <sm:h-22.5rem">
            </div>
          </div>
          <div style={{ background: 'linear-gradient(360deg, white 68%, rgba(255, 255, 255, 0) 100%)' }} className="absolute left-50% top-31rem -translate-x-50% w-1086px h-250.78px z-3 <lg:w-54rem <lg:h-12rem <lg:top-29rem <md:w-46rem <md:top-25rem <sm:hidden" />
          <div className='hidden absolute left-50% -translate-x-50% top-11rem justify-between items-center w-100% h-100% <sm:flex z-5'>
            <div className="w-full h-full " style={{
              background: 'linear-gradient(90deg, #FFF 14%, rgba(255, 255, 255, 0.00) 60%)'
            }} />
            <div className="w-full h-full " style={{
              background: 'linear-gradient(270deg, #FFF 14%, rgba(255, 255, 255, 0.00) 60%)'
            }} />
          </div>
          <div className="absolute left-2% top-10rem -translate-x-50% w-65rem h-65rem product-rotate rounded-full z-1 <lg:w-50rem <lg:h-50rem <md:w-44rem <md:h-44rem <sm:w-36rem <sm:h-36rem">
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-27% top-2rem <lg:top-3rem <lg:left-20%  w-6rem h-6rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <md:left-26% <md:top-1rem <sm:w-4.2rem <sm:h-4.2rem <sm:top-0">
              <img src="/icons/chart-icon.svg" alt="chart icon" className='w-1.7rem h-1.7rem <sm:w-1.2rem <sm:h-1.2rem' />
              <p className="font-Montserrat text-center text-0.65rem font-700 text-darkBlue <lg:text-0.5rem ">Analytics
                and
                Data Insights</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-67% top-3.5rem <lg:top-3rem <lg:left-69% w-5.2rem h-5.2rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <md:top-2rem <sm:w-4.2rem <sm:h-4.2rem <sm:top-0 <sm:left-59%">
              <img src="/icons/Responsive-icon.svg" alt="chart icon" className='<sm:w-1.5rem <sm:h-1.5rem' />
              <p className="font-Montserrat text-center text-0.65rem font-700 text-darkBlue <lg:text-0.5rem ">Responsive
                Design</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-85% top-46rem <lg:top-35rem <lg:left-84% <md:top-26rem <md:left-88% w-5rem h-5rem rounded-full p-0.5rem <lg:w-4.7rem <lg:h-4.7rem <sm:w-4.2rem <sm:h-4.2rem <sm:top-21rem <sm:left-90% ">
              <img src="/icons/search-engine-icon.svg" alt="chart icon" className='w-1.5rem h-1.5rem <sm:w-1rem <sm:h-1rem' />
              <p className="font-Montserrat text-center text-0.5rem font-700 text-darkBlue <lg:text-0.5rem <sm:mb-7px">Search Engine Optimization</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-0% top-37rem <lg:top-30rem <lg:left-17px <md:left-4px <md:top-26rem w-7.4rem h-7.4rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <sm:w-4.2rem <sm:h-4.2rem <sm:top-20rem <sm:-left-11px ">
              <img src="/icons/folder-shield-icon.svg" alt="chart icon" className='w-1.75rem h-1.75rem <sm:w-1.2rem <sm:h-1.2rem' />
              <p className="font-Montserrat text-center text-0.88rem font-700 text-darkBlue <lg:text-0.5rem ">Security and Compliance</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-6% top-16rem <lg:top-14rem <lg:left-3% <md:top-11rem <md:left-3% <sm:-left-1% w-5rem h-5rem rounded-full p-0.5rem <lg:w-4.7rem <lg:h-4.7rem <sm:w-4.2rem <sm:h-4.2rem">
              <img src="/icons/search-engine-icon.svg" alt="chart icon" className='w-1.5rem h-1.5rem <sm:w-1rem <sm:h-1rem' />
              <p className="font-Montserrat text-center text-0.5rem font-700 text-darkBlue <lg:text-0.5rem <sm:mb-7px">Search Engine Optimization</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-87% top-19.4rem <lg:top-16.4rem <lg:left-89% <md:left-88% <md:top-13.4rem w-7.4rem h-7.4rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <sm:w-4.2rem <sm:h-4.2rem <sm:top-11.4rem <sm:left-89%">
              <img src="/icons/folder-shield-icon.svg" alt="chart icon" className='w-1.75rem h-1.75rem <sm:w-1.2rem <sm:h-1rem' />
              <p className="font-Montserrat text-center text-0.88rem font-700 text-darkBlue <lg:text-0.5rem ">Security and Compliance</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-57% top-58rem <lg:top-45rem <lg:left:64% <md:top-38rem <md:left-63% w-6.1rem h-6.1rem  rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <sm:w-4.2rem <sm:h-4.2rem <sm:top-31rem">
              <img src="/icons/chart-icon.svg" alt="chart icon" className='w-1.7rem h-1.7rem <sm:w-1.2rem <sm:h-1.2rem' />
              <p className="font-Montserrat text-center text-0.65rem font-700 text-darkBlue <lg:text-0.5rem ">Analytics
                and
                Data Insights</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-21% top-55rem <lg:top-42rem <lg:left-19% <md:top-38rem <md:left-26% w-5.2rem h-5.2rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem  <sm:w-4.2rem <sm:h-4.2rem <sm:top-31rem">
              <img src="/icons/Responsive-icon.svg" alt="chart icon" className='<sm:w-1.5rem <sm:h-1.5rem' />
              <p className="font-Montserrat text-center text-0.65rem font-700 text-darkBlue <lg:text-0.5rem ">Responsive
                Design</p>
            </span>
          </div>
          <div className="absolute left-15% top-19rem -translate-x-50% w-47rem h-47rem product-rotate rounded-full z-1 <lg:left-12% <lg:top-16rem <lg:h-40rem <lg:w-40rem <md:w-35rem <md:h-35rem <md:left-11% <md:top-15rem <sm:w-29rem <sm:h-29rem">
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute -left-7% top-19rem w-4.8rem h-4.8rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <lg:-left-30px <lg:top-16rem <md:left-55px <md:top-3.4rem <sm:left-10% <sm:top-2rem <sm:w-4.2rem <sm:h-4.2rem">
              <img src="/icons/branding-icon.svg" alt="chart icon" className='<sm:w-1.2rem <sm:h-1.2rem' />
              <p className="font-Montserrat text-center text-0.6rem font-700 text-darkBlue <lg:text-0.5rem ">Branding and Identity</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-48% -top-3.5rem w-4.8rem h-4.8rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <lg:left-46% <lg:-top-32px <md:left-80% <md:top-4rem <sm:left-80% <sm:top-45px <sm:w-4.2rem <sm:h-4.2rem">
              <img src="/icons/custom-development-icon.svg" alt="chart icon" className='w-1.5rem h-1.5rem <sm:w-1.2rem <sm:h-1.2rem' />
              <p className="font-Montserrat text-center text-0.6rem font-700 text-darkBlue mb-9px <lg:text-0.5rem <sm:mb-7px">Custom Development</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-97% top-24rem w-4.8rem h-4.8rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <lg:left-92% <lg:top-19rem <md:left-82% <md:top-26.3rem <sm:left-79% <md:top-22.7rem <sm:top-22rem <sm:w-4.2rem <sm:h-4.2rem">
              <img src="/icons/branding-icon.svg" alt="chart icon" className='<sm:w-1rem <sm:h-1rem' />
              <p className="font-Montserrat text-center text-0.6rem font-700 text-darkBlue <lg:text-0.5rem ">Branding and Identity</p>
            </span>
            <span style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)' }} className="item-rotate flex flex-col justify-center items-center overflow-hidden bg-white absolute left-35% top-45.2rem w-4.8rem h-4.8rem rounded-full p-0.8rem <lg:w-4.7rem <lg:h-4.7rem <lg:left-37% <lg:top-37rem <md:top-25rem <md:left-4% <sm:left-5% <sm:top-21rem <sm:w-4.2rem <sm:h-4.2rem">
              <img src="/icons/custom-development-icon.svg" alt="chart icon" className='w-1.5rem h-1.5rem <sm:w-1.2rem <sm:h-1.2rem' />
              <p className="font-Montserrat text-center text-0.6rem font-700 text-darkBlue mb-9px <lg:text-0.5rem <sm:mb-7px">Custom Development</p>
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-between items-center gap-1rem relative -top-9rem <lg:-top-12rem <lg:gap-0.75rem <md:-top-9rem <sm:gap-0.6 z-5 <sm:-top-5rem <sm:max-w-26.5rem mx-auto">
          <img src="/icons/pheno-logo.svg" alt="pheno logo" width='120' height='120' className="z-1 <md:w-2.9rem <md:h-2.9rem" />
          <p className="text-14px font-400 leading-101% mt-0.5rem text-hex-4C97DC text-center font-Montserrat z-1 <lg:text-12px <md:text-10px">Maximize your product's potential with our tailored solutions
          </p>
          <h4 className="w-478px text-center text-darkBlue text-40px font-bold leading-10 <lg:text-2rem z-1 <lg:w-23rem <sm:text-1.75rem <sm:w-78% <sm:max-w-26rem <sm:leading-2rem">What we can do to improve your product</h4>
          <p className="w-581.99px text-center text-gray text-14px font-medium leading-150% <lg:text-12px z-1 <lg:w-27rem <sm:w-90% <sm:max-w-22rem font-Montserrat">Elevate your product with Pheno's customized solutions, transforming it into a digital masterpiece that captivates and delights</p>
          <Button title='See all pheno services' link='/services' titleClassName='!text-16px <lg:!text-14px' />
        </div>
      </div>
    )
  }
  return (
    <div className='w-full'>

      <StaticImproveProduct />
    </div>
  )
}
